// import { User } from "../../../user/models/user.model";
import { Song, SongRequestDetails } from "../../songs/models/song.model";
import { GigNowPlaying } from "./gig-now-playing";
import { GigNotice } from "./gig-notice";
import { v4 as uuidv4 } from 'uuid';
import { Timestamp } from "@angular/fire/firestore";

export class Gig {
    // They have a name, happen at a location, and start and end at times.
    id: string;
    name: string;
    location: {
        id: string;
        name: string;
    }

    // Gigs belong to one owner who schedules it
    owner_id: string;
    schedule: {
        start: Date | Timestamp | any;
        end: Date | Timestamp | any | null;
    };

    // Gigs have a few bool settings
    live: boolean;
    public: boolean;
    ticketed: boolean;
    accepting_requests: boolean;

    // They can also have other performers, or an Ensemble.
    ensemble_ids: string[];

    // messages: {
    //     starting: string;
    //     break: string;
    //     ending: string;
    //     custom: string[];
    // };


    /* Songs and Music
        Performer has Songs, which are added to songlists
        Starting a Gig, the Songs on the Songlist become the catalog for the gig

        Catalog contains songs (that are on the songlist)
        fans request songs
        performer plays a catalog/requested song, moves to NowPlaying
        outgoing nowplaying objects are moved to setlist
    */
    songlist_id: string;
    catalog: Song[];
    songs_requested: { [id: string]: SongRequestDetails };
    nowplaying: GigNowPlaying;
    setlist: GigNowPlaying[];

    stats: {
        total: {
            catalog_songs: number,
            songs_played: number,
            songs_requested: number,    // Same song requested twice == 1!!!
            requests: number,           // Same song requested twice == 2!!! 

            tipcount: number,           // Tony and Dave tip == 2
            tipvalue: number,           // Tony tips 100 Dave cheaps out with $0.45 == $100.45
            fans: number,               // how many unique fans/guests were ever connected to this gig
        }
    };
    notices: GigNotice[];
    fans: any;
    /* This is a tracking measurement / caching object to
    //   - keep a UserId cached for each person who has connected to the gig via live
            - Each user is a KV pair (userID, <data>) on the fans object:

            fans = {
                attendence: {
                    'aslkjewhljkqhner': User<{
                        name: 'Tony',
                        songs_requested: {
                            'songIdA': [individualRequest,]
                            'songIdb': [individualRequest,]
                        }
                        interactions: [...,]
                    }>,
                    'qeafdashhnqnqewr': User<dave>,
                }
                timestamp/checkins: [
                    ** Add an object like {
                        time: timestamp,
                        user: 'aslkjewhljkqhner'
                        interaction: 'load-gig' | 'reqest-song', 'now-playing-changed,

                        interaction_details: ||| song_request, with tip, etc
                        
                    }
                ]
            }
    */

    constructor() {
        this.id = '';
        this.name = '';
        this.location = {
            id: null,
            name: null,
        }

        this.owner_id = '';
        this.schedule = {
            start: new Date,
            end: null
        };

        // Gig Logicstics and details
        this.live = false;
        this.public = true;
        this.ticketed = false;
        this.ensemble_ids = [];
        if (this.owner_id.length > 0) {
            this.ensemble_ids.push(this.owner_id);
        }

        // Music details
        this.accepting_requests = true;
        this.songlist_id = '';
        this.catalog = [];
        this.songs_requested = {};
        this.nowplaying = new GigNowPlaying('Now Playing')
        this.setlist = [];

        // Stats and Notices
        this.stats = {
            total: {
                catalog_songs: 0,
                songs_played: 0,
                songs_requested: 0,
                requests: 0,
                tipcount: 0,
                tipvalue: 0,
                fans: 0,
            }
        }
        this.notices = [];
        this.fans = {};
    }
}
