import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { Gig } from '../../tiptunes/performer/gigs/models/gig';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  public title: BehaviorSubject<any> = new BehaviorSubject('TipTunes');
  public subTitle: BehaviorSubject<any> = new BehaviorSubject('');
  public activeGig: BehaviorSubject<Gig> = new BehaviorSubject(new Gig);
  // public activeGig$: Observable<Gig | null> = new Observable(null)

  constructor() {
  }
  setTitles(newTitle: string, newSubTitle: string = '') {
    this.title.next(newTitle);
    this.subTitle.next(newSubTitle);
  }
  setActiveGig(activeGig: any) {
    this.activeGig.next(activeGig);
  }
  clearActiveGig() {
    this.activeGig.next(null);
  }
  getTitle(): Observable<string> {
    return this.title.asObservable();
  }
  getSubTitle(): Observable<string> {
    return this.subTitle.asObservable();
  }
  getActiveGig(): Observable<Gig> {
    return this.activeGig.asObservable();
  }
}
