import { Song } from "../../songs/models/song.model";
import { User } from "../../../../shared/auth/interfaces/user";

export class GigNowPlaying {
    // id: string;
    // performer: User;
    start?: Date;
    end?: Date;
    value?: any;
    type?: 'song' | 'gig-start' | 'gig-end' | 'gig-break';

    constructor(song: any) {
        this.start = new Date();
        this.end = null;
        this.type = 'song'
        this.value = song;
    }
}
