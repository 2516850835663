import { Component } from '@angular/core';
const { version: appVersion } = require('../../../../../package.json')

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <div class="socials">
      <a href="#" target="_blank" class="ion ion-social-github"></a>
      <a href="#" target="_blank" class="ion ion-social-facebook"></a>
      <a href="#" target="_blank" class="ion ion-social-twitter"></a>
      <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
    </div>
    <span class="ttversion">Version: {{ version }}</span>
  `,
})
export class FooterComponent {
  public version: string;
  constructor() {
    this.version = appVersion;
  }
}
