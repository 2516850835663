<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">{{ title | async }}</a>
  </div>

  <div *ngIf="activeGig$ | async as activeGig">
    {{ activeGig.name }}
  </div>
</div>
